/* We need to add display:inline in order to align the '>>' of the 'read more' link */
.post-excerpt p {
	display:inline;
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
	"syntax",
    "starsnonscss",
	"roddet"
;
